var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _vm.userHasPermission("apps_customers_edit")
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "ml-4 sm:mb-0 mb-2",
                        on: { click: _vm.addCompany },
                      },
                      [_vm._v("\n          Add Company\n        ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.companies,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                context: _vm.context,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true,
              },
              on: { "grid-size-changed": _vm.onGridSizeChanged },
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: 5 },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }